import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import HeroV8 from "../components/HeroV8";
import FeaturedContent from "../components/FeaturedContent";

import rehypeReact from "rehype-react";
import Button from "../components/Button";
import Cta from "../components/CTA";
import ReactLightboxV2 from "../components/ReactLightboxV2";

import {
  dhlFeaturedContent,
  dhlProjectGalleryHeader,
  ladbrokesFeaturedContent,
  ladbrokesProjectGalleryHeader,
  medivetFeaturedContent,
  medivetProjectGalleryHeader,
  biffaFeaturedContent,
  biffaProjectGalleryHeader,
  harveyNicholsFeaturedContent,
  harveyNicholsProjectGalleryHeader,
  theHubFeaturedContent,
  theHubProjectGalleryHeader,
  domesticInstallationFeaturedContent,
  domesticInstallationGalleryHeader,
  herschelGrammarFeaturedContent,
  herschelGrammarGalleryHeader,
  pioneerTrustFeaturedContent,
  pioneerTrustGalleryHeader,
} from "../constants";

import styles from "../styles/style";

const Project = ({ data }) => {
  //console.log(data);

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { button: Button, cta: Cta },
  }).Compiler;

  const { htmlAst } = data.markdownRemark;
  const {
    title,
    heroImage,
    heroTitleTop,
    heroTitleBottom,
    excerpt,
    btnLeft,
    btnLeftLink,
    btnRight,
    btnRightLink,
  } = data.markdownRemark.frontmatter;

  const dhlfeaturedImages = data.dhlfeaturedImages.nodes;
  const ladbrokesFeaturedImages = data.ladbrokesfeaturedImages.nodes;
  const medivetfeaturedImages = data.medivetfeaturedImages.nodes;
  const biffafeaturedImages = data.biffafeaturedImages.nodes;
  const harveyNicholsfeaturedImages = data.harveyNicholsfeaturedImages.nodes;
  const theHubfeaturedImages = data.theHubfeaturedImages.nodes;
  const domesticInstallationfeaturedImages = data.domesticInstallationfeaturedImages.nodes;
  const herschelGrammarfeaturedImages = data.herschelGrammarfeaturedImages.nodes;
  const pioneerTrustfeaturedImages = data.pioneerTrustfeaturedImages.nodes;

  let backgroundImg = getImage(heroImage?.childImageSharp?.gatsbyImageData);

  return (
    <>
      <Seo 
        title={title}
        description={excerpt}
        post={true}
        metaRobots="follow, index"
      />
      <HeroV8
        heroImage={backgroundImg}
        heroTitleTop={heroTitleTop}
        heroTitleBottom={heroTitleBottom}
        excerpt={excerpt}
        btnLeft={btnLeft}
        btnLeftLink={btnLeftLink}
        btnRight={btnRight}
        btnRightLink={btnRightLink}
      />

      {title && title === "DHL - Reading" ? (
        <FeaturedContent
          featuredContent={dhlFeaturedContent}
          featuredContentImages={dhlfeaturedImages}
        />
      ) : title && title === "Ladbrokes Woking Project" ? (
        <FeaturedContent
          featuredContent={ladbrokesFeaturedContent}
          featuredContentImages={ladbrokesFeaturedImages}
        />
      ) : title && title === "Medivet Watford Project" ? (
        <FeaturedContent
          featuredContent={medivetFeaturedContent}
          featuredContentImages={medivetfeaturedImages}
        />
      ) : title && title === "Biffa Workshop Project" ? (
        <FeaturedContent
          featuredContent={biffaFeaturedContent}
          featuredContentImages={biffafeaturedImages}
        />
      ) : title && title === "Harvey Nichols chiller upgrade" ? (
        <FeaturedContent
          featuredContent={harveyNicholsFeaturedContent}
          featuredContentImages={harveyNicholsfeaturedImages}
        />
      ) : title && title === "The Hub - Farnborough" ? (
        <FeaturedContent
          featuredContent={theHubFeaturedContent}
          featuredContentImages={theHubfeaturedImages}
        />
      ) : title && title === "Domestic Installation Project" ? (
        <FeaturedContent
          featuredContent={domesticInstallationFeaturedContent}
          featuredContentImages={domesticInstallationfeaturedImages}
        />
      ) : title && title === "Herschel Grammar Contract" ? (
        <FeaturedContent
          featuredContent={herschelGrammarFeaturedContent}
          featuredContentImages={herschelGrammarfeaturedImages}
        />
      ) : title && title === "Pioneer trust school contract" ? (
        <FeaturedContent
          featuredContent={pioneerTrustFeaturedContent}
          featuredContentImages={pioneerTrustfeaturedImages}
        />
      ) : null}

      <div className="w-full mx-auto  article mb-4">
        <div className={`bg-[#FFFFFF] ${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <section className="">
              <div>{renderAst(htmlAst)}</div>
            </section>
          </div>
        </div>
      </div>

      {title && title === "DHL - Reading" ? (
        <ReactLightboxV2
          images={data.dhlGalleryImages.nodes}
          galleryContent={dhlProjectGalleryHeader}
          location="dhl-project"
        />
      ) : title && title === "Ladbrokes Woking Project" ? (
        <ReactLightboxV2
          images={data.ladbrokesGalleryImages.nodes}
          galleryContent={ladbrokesProjectGalleryHeader}
          location="ladbrokes-project"
        />
      ) : title && title === "Medivet Watford Project" ? (
        <ReactLightboxV2
          images={data.medivetGalleryImages.nodes}
          galleryContent={medivetProjectGalleryHeader}
          location="medivet-project"
        />
      ) : title && title === "Biffa Workshop Project" ? (
        <ReactLightboxV2
          images={data.biffaGalleryImages.nodes}
          galleryContent={biffaProjectGalleryHeader}
          location="medivet-project"
        />
      ) : title && title === "Harvey Nichols chiller upgrade" ? (
        <ReactLightboxV2
          images={data.harveyNicholsGalleryImages.nodes}
          galleryContent={harveyNicholsProjectGalleryHeader}
          location="medivet-project"
        />
      ) : title && title === "The Hub - Farnborough" ? (
        <ReactLightboxV2
          images={data.theHubGalleryImages.nodes}
          galleryContent={theHubProjectGalleryHeader}
          location="medivet-project"
        />
      ) : title && title === "Domestic Installation Project" ? (
        <ReactLightboxV2
          images={data.domesticInstallationGalleryImages.nodes}
          galleryContent={domesticInstallationGalleryHeader}
          location="medivet-project"
        />
      ) : title && title === "Herschel Grammar Contract" ? (
        <ReactLightboxV2
          images={data.herschelGrammarGalleryImages.nodes}
          galleryContent={herschelGrammarGalleryHeader}
          location="medivet-project"
        />
      ) : title && title === "Pioneer trust school contract" ? (
        <ReactLightboxV2
          images={data.pioneerTrustGalleryImages.nodes}
          galleryContent={pioneerTrustGalleryHeader}
          location="medivet-project"
        />
      ) : null}
    </>
  );
};

export default Project;

export const query = graphql`
  query ProjectQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      htmlAst
      frontmatter {
        title
        tag
        heroTitleTop
        heroTitleBottom
        excerpt
        btnLeft
        btnLeftLink
        btnRight
        btnRightLink
        # images {
        #   name
        #   childImageSharp {
        #     gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        #   }
        # }
        heroImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 2.44
            )
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
    dhlfeaturedImages: allFile(
      filter: {
        name: { in: ["dhl-gallery-06", "dhl-gallery-12", "dhl-gallery-14"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    ladbrokesfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "03-ladbrokes-project"
            "04-ladbrokes-project"
            "06-ladbrokes-project"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    medivetfeaturedImages: allFile(
      filter: {
        name: {
          in: ["01-medivet-project", "06-medivet-project", "09-medivet-project"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    biffafeaturedImages: allFile(
      filter: {
        name: {
          in: ["08-biffa-roller-shutter", "biffa-gallery-09", "biffa-gallery-16"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    harveyNicholsfeaturedImages: allFile(
      filter: {
        name: {
          in: ["03-harvey-nichols-installation", "05-harvey-nichols-installation", "06-harvey-nichols-installation"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    theHubfeaturedImages: allFile(
      filter: {
        name: {
          in: ["01-the-hub", "03-the-hub", "06-the-hub"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    domesticInstallationfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "07-local-installation"
            "08-local-installation"
            "09-local-installation"   
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    herschelGrammarfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "02-herschel-grammar-outdoor-electrics"
            "herschel-grammar-school-celing-lights"
            "herschel-grammar-school"   
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    pioneerTrustfeaturedImages: allFile(
      filter: {
        name: {
          in: [
            "01-upton-court-grammar-school"
            "02-trevelyan-middle-school"
            "03-foxborough-primary-school"   
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    dhlGalleryImages: allFile(
      filter: {
        name: {
          in: [
           
            "dhl-gallery-03"
            "dhl-gallery-04"
            "dhl-gallery-05"
            "dhl-gallery-08"
            "dhl-gallery-10"
            "dhl-gallery-16"

          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }    
    ladbrokesGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "01-ladbrokes-project"
            "02-ladbrokes-project"
            "03-ladbrokes-project"
            "04-ladbrokes-project"
            "05-ladbrokes-project"
            "06-ladbrokes-project"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    medivetGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "04-medivet-project"
            "05-medivet-project"
            "07-medivet-project"
            "08-medivet-project"
            "11-medivet-project"
            "12-medivet-project"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    biffaGalleryImages: allFile(
      filter: {
        name: {
          in: 
          [
            "biffa-gallery-01"
            "biffa-gallery-04"
            "biffa-gallery-06"
            "biffa-gallery-08"
            "biffa-gallery-10"
            "biffa-gallery-12"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    harveyNicholsGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "01-harvey-nichols-installation", 
            "05-harvey-nichols-installation", 
            "08-harvey-nichols-installation", 
            "07-harvey-nichols-installation",
            "10-harvey-nichols-installation",
            "14-harvey-nichols-installation",
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    theHubGalleryImages: allFile(
      filter: {
        name: {
          in: ["03-the-hub", "01-the-hub", "250"]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    domesticInstallationGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "01-local-installation"
            "02-local-installation"
            "03-local-installation"
            "04-local-installation"
            "05-local-installation"
            "10-local-installation"    
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    herschelGrammarGalleryImages: allFile(
      filter: {
        name: {
          in: [
            "herschel-grammar-accent-lights"
            "herschel-grammar-school-celing-lights"
            "herschel-grammar-school-office-celing-lights"
            "herschel-grammar-school-reception-celing-lights"            
            "herschel-grammar-school-sports-centre-celing-lights"
            "herschel-grammar-school-trunking"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
    pioneerTrustGalleryImages: allFile(
      filter: {
        name: {
          in: [            
            "01-pioneer-trust-electrical-work"
            "04-pioneer-trust-electrical-work"
            "08-pioneer-trust-electrical-work"
            "12-pioneer-trust-electrical-work"
            "16-pioneer-trust-electrical-work"
            "20-pioneer-trust-electrical-work"
          ]
        }
      }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(
            formats: AUTO
            layout: CONSTRAINED
            placeholder: BLURRED
            aspectRatio: 1.1
          )
        }
      }
    }
  }
`;
